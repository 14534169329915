import { Swiper } from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// Breakpoints must be in order from smallest to largest
const BREAKPOINTS = ['sm', 'md', 'lg'];

/**
 * Check if target breakpoint is contained by current breakpoint or higher
 * @param {string} targetBreakpoint A valid breakpoint (sm, md, lg)
 * @param {string} currentBreakpoint A valid breakpoint (sm, md, lg)
 * @returns {boolean} If target breakpoint is contained by given breakpoint
 */
function isEquivalentBreakpoint(targetBreakpoint, currentBreakpoint) {
    // validBreakpoints are the current breakpoint and higher
    const validBreakpoints = BREAKPOINTS.slice(
        BREAKPOINTS.indexOf(currentBreakpoint)
    );

    return validBreakpoints.includes(targetBreakpoint);
}

/**
 * Obtains validated slides-per-view value. NaN is valid value because
 * is evaluated by getBreakpointSlidesPerView -> slidesPerBreakpoint as falsy.
 * @param {string|undefined} value Configured slides-per-view value
 * @return {string|number|NaN} Valid checkable slides
 */
function getValidSlides(value) {
    return value === 'auto' ? value : +value;
}

/**
 * Gets the valid number of slides per view for given breakpoint
 * @param {HTMLElement} container Carousel container
 * @param {string} targetBreakpoint The desired breakpoint (sm, md, lg)
 * @returns {number|string} The number of slides per view for given breakpoint
 */
function getBreakpointSlidesPerView(container, targetBreakpoint) {
    const slidesPer = {
        sm: getValidSlides(container.dataset.slidesPerViewSm),
        md: getValidSlides(container.dataset.slidesPerViewMd),
        lg: getValidSlides(container.dataset.slidesPerViewLg)
    };

    return BREAKPOINTS.reduce((acc, breakpoint) => {
        const slidesPerBreakpoint = slidesPer[breakpoint];

        if (isEquivalentBreakpoint(targetBreakpoint, breakpoint) && slidesPerBreakpoint) {
            return slidesPerBreakpoint;
        }

        return acc;
    }, 'auto');
}

function getSpaceBetween(container, targetBreakpoint) {
    const slidesPer = {
        sm: getValidSlides(container.dataset.spaceBetweenSm),
        md: getValidSlides(container.dataset.spaceBetweenMd),
        lg: getValidSlides(container.dataset.spaceBetweenLg)
    };

    return BREAKPOINTS.reduce((acc, breakpoint) => {
        const slidesPerBreakpoint = slidesPer[breakpoint];

        if (isEquivalentBreakpoint(targetBreakpoint, breakpoint) && slidesPerBreakpoint) {
            return slidesPerBreakpoint;
        }

        return acc;
    }, 'auto');
}

/**
 * Add auto rotation to carousel configuration if needed.
 * @param {*} container Carousel container
 * @param {*} options Carousel configuration
 */
function addAutoRotation(container, options) {
    if (container.classList.contains('auto-rotation')) {
        options.autoplay = {
            delay: +container.dataset.autoplayDelay || 5000,
            disableOnInteraction: false
        };
    }
}

/**
 * Fix wrong behavior for empty carousels
 * @param {*} container Carousel container
 * @param {*} options Carousel configuration
 * @param {*} carousel Carousel markup
 */
function fixLoopProblemWithEmptySliders(container, options, carousel) {
    if (carousel.querySelector('.swiper-slide')) {
        options.loop = !container.classList.contains('no-infinite-rotation');
    }
}

/**
 * Preloads the timeslot selection based on the selected timeslot data.
 * Adds the 'selected' class to matching date and timeslot elements and slides the swiper to the correct position.
 *
 * @param {Object} swiperSlider - The swiper slider instance.
 */
function preloadTimeslotSelection(swiperSlider) {
    const { selectedTimeslot } = require('./timeslot').getSelectedShipmentAndSlotData();
    if (selectedTimeslot) {
        const selectedSlotDay = new Date(selectedTimeslot.start).getUTCDate();
        $('.timeslot-table__date-element').each(function iterateDays() {
            const currentDay = $(this);
            const day = new Date(currentDay.data('day')).getUTCDate();
            if (selectedSlotDay === day) {
                currentDay.addClass('selected');
                swiperSlider.slideTo(currentDay.data('slide'));
            }
        });

        $('div.timeslot-table__slot-content-element').each(function iterateSlots() {
            const currentSlot = $(this);
            if (currentSlot.data('start') === selectedTimeslot.start && currentSlot.data('end') === selectedTimeslot.end) {
                currentSlot.addClass('selected');
            }
        });
    }
}

/**
 * Initializes swiper on the given container
 * @param {HTMLElement} container Carousel container
 */
function initializeSwiper(container) {
    const carousel = container.querySelector('.swiper-container');
    const options = {
        modules: [Autoplay, Navigation, Pagination],
        preloadImages: false,
        lazy: {
            loadOnTransitionStart: true,
            loadPrevNext: true
        },
        watchSlidesVisibility: true
    };

    addAutoRotation(container, options);

    const swiper = new Swiper(
        carousel,
        Object.assign({
            centerInsufficientSlides: container.classList.contains('no-infinite-rotation') || container.classList.contains('center-slides'),
            autoHeight: container.classList.contains('auto-height'),
            breakpoints: {
                769: {
                    slidesPerView: getBreakpointSlidesPerView(container, 'sm'),
                    spaceBetween: getSpaceBetween(container, 'sm')
                },
                992: {
                    slidesPerView: getBreakpointSlidesPerView(container, 'md'),
                    spaceBetween: getSpaceBetween(container, 'md')
                },
                1199: {
                    slidesPerView: getBreakpointSlidesPerView(container, 'lg'),
                    spaceBetween: getSpaceBetween(container, 'lg')
                }
            },
            on: {
                afterInit: function afterInit(swiperSlider) {
                    const swiperSlides = container.querySelectorAll('.swiper-slide');
                    swiperSlides.forEach(slide => slide.classList.add('visible'));

                    if (document.querySelectorAll('.swiper-container').length === 1) {
                        preloadTimeslotSelection(swiperSlider);
                    }
                },
                slideChange: function selectCurrentDay(swiperSlider) {
                    if (container.classList.contains('update-selected-day')) {
                        const dateElements = container.parentElement.querySelectorAll('.timeslot-table__date-element');
                        dateElements.forEach((dateElement) => {
                            dateElement.classList.remove('selected');
                            let slide = parseInt(dateElement.getAttribute('data-slide'), 10);
                            if (slide === swiperSlider.activeIndex) {
                                dateElement.classList.add('selected');
                            }
                        });
                    }
                }
            }
        }, options)
    );

    container.swiper = swiper;
}

export const methods = {
    initializeSwiper,
    fixLoopProblemWithEmptySliders,
    getBreakpointSlidesPerView
};
