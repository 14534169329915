const base = require('base/components/menu');

function readyPage() {
    $(document).ready(() => {
        if (window.innerWidth < 961) {
            $('.nav-item.dropdown .category-img').hide();
        } else {
            $('.nav-item.dropdown .category-text').hide();
        }
    });
}

function resizePage() {
    $(window).on('resize', () => {
        if (window.innerWidth < 961) {
            $('.main-menu .navbar-nav li img').hide();
            $('.nav-item.dropdown .category-text').show();
        } else {
            $('.nav-item.dropdown .category-text').hide();
            $('.main-menu .navbar-nav li img').show();
        }
    });
}

/**
 * Dynamically calculated padding to add on top of the page when using fixed header positioning
 */
function addPaddingToPage() {
    if ($('header').css('position') === 'fixed') {
        $('.page').css('paddingTop', $('header').height());
    }
}

function manageScrollHeader() {
    var y = window.scrollY;
    if (y > 100) {
        $('.header-ak').addClass('sticky');
    } else {
        $('.header-ak').removeClass('sticky');
    }
}

module.exports = {
    base,
    function() {
        $('.navbar-header .user .login-logout').on('mouseenter focusin', () => {
            if ($('.navbar-header .user .login-logout .popover').length > 0) {
                $('.navbar-header .user .login-logout .popover').addClass('show');
                $('.user .login-logout').attr('aria-expanded', 'true');
            }
        });

        $('.navbar-header .user .login-logout').on('mouseleave', () => {
            $('.navbar-header .user .login-logout .popover').removeClass('show');
            $('.user .login-logout').attr('aria-expanded', 'false');
        });

        $(document).on('click', '.add-address', () => {
            $('.delivery-address-options').hide();
            $('.cm-add-address').hide();
            $('.delivery-add-address').show();
            $('.cm-cancel-add-address').show();
        });

        $(document).on('click', '.cm-cancel-add-address', () => {
            $('.delivery-address-options').show();
            $('.cm-add-address').show();
            $('.delivery-add-address').hide();
            $('.cm-cancel-add-address').hide();
        });

        addPaddingToPage();
        $(window).resize(manageScrollHeader).scroll(manageScrollHeader);
        readyPage();
        resizePage();

        $('body').on('click', '.dropdown-menu .close-button', (e) => {
            e.preventDefault();
            $('.menu-account-mobile').find('.dropdown-menu').removeClass('show');
            $('.menu-account-mobile').find('.dropdown-toggle').attr('aria-expanded', 'false');
            $('.menu-account-mobile').find('.dropown.nav-item').removeClass('show');
        });
    }
};
