
$(() => {
    $('.js-open-filters').on('click', () => {
        $('.grid-list__left').addClass('open');
    });

    $('.js-close-filters').on('click', () => {
        $('.grid-list__left').removeClass('open');
    });
});
