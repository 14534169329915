'use strict';

const focusHelper = require('base/components/focus');

/**
 * setConsentTrackingEvents
 */
function setConsentTrackingEvents() {
    $('#consent-tracking .button-wrapper button').click(function (e) {
        e.preventDefault();
        const url = $(this).data('url');
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(response) {
                localStorage.setItem('tracking_consent', response.consent);
                $('#consent-tracking').remove();
                $.spinner().stop();
            },
            error() {
                $('#consent-tracking').remove();
                $.spinner().stop();
            }
        });
    });
}

/**
 * showConsentModal
 */
function showConsentModal() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    const urlContent = $('.tracking-consent').data('url');
    $.spinner().start();

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success(response) {
            $('body').append(response);
            setConsentTrackingEvents();
        },
        error() {
            $('#consent-tracking').remove();
        }
    });
}

module.exports = () => {
    if ($('.tracking-consent').hasClass('api-true')) {
        if (!window.localStorage.getItem('tracking_consent')) {
            showConsentModal();
        } else if ($('.consented').length === 0) {
            const trackingConsentData = $('.tracking-consent');
            const urlAccept = trackingConsentData.data('accept');
            const urlReject = trackingConsentData.data('reject');
            const tokenName = trackingConsentData.data('tokenname');
            const token = trackingConsentData.data('token');
            const data = {};
            const url = window.localStorage.getItem('tracking_consent') ? urlAccept : urlReject;
            data[tokenName] = token;
            $.ajax({
                url,
                type: 'post',
                data: data,
                dataType: 'json'
            });
        }
    }

    $('body').on('shown.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'true');
        $('#consent-tracking .affirm').focus();
    });

    $('body').on('hidden.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#consent-tracking', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#consent-tracking',
            firstElementSelector: '.affirm',
            lastElementSelector: '.decline',
            nextToLastElementSelector: '.affirm'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
};
