$(() => {
    $('body').on('click', '.accordion-list .accordion-list__title', (e) => {
        e.preventDefault();
        const self = e.currentTarget;

        $(self).closest('li').toggleClass('active');
        $(self).toggleClass('active');
        $(self).next('.accordion-list__answer').slideToggle(100);

        return false;
    });

    $('body').on('click', '.accordion-all-open__element-btn', (e) => {
        e.preventDefault();

        const self = e.target;
        const content = $(self).siblings('.accordion-all-open__element-content');

        content.toggleClass('opened');
        $(self).toggleClass('active', content.hasClass('opened'));
    });

    $('.js-open-block').on('click', (e) => {
        const $currentElement = $(e.target);
        const accordionParent = $currentElement.closest('.accordion-v2');
        accordionParent.toggleClass('hidden');
        $('.accordion-v2').not(accordionParent).addClass('hidden');

        const topPosition = accordionParent.offset().top - 200;
        $('html, body').animate({
            scrollTop: topPosition
        }, 500);
    });

    $('body').on('click', '.accordion-simple-toggle', (e) => {
        e.preventDefault();

        let $this = $(e.currentTarget);
        let inner = $this.next();

        if (inner.hasClass('show')) {
            inner.removeClass('show');
            inner.slideUp(350);
            $this.removeClass('active');
        } else {
            $('.accordion-simple-toggle').removeClass('active');
            $('.accordion-simple-inner').removeClass('show').slideUp(350);

            inner.addClass('show');
            inner.slideToggle(350);
            $this.addClass('active');
        }
    });
});
