$(() => {
    const CheckoutDOMUtils = require('../checkout/DOMUtils');

    $(document).on('click', '.js-element__delete', function removeTableRow() {
        const element = $(this).closest('.table-element-delete');
        const ID = $(element).data('pid');

        $('body').trigger('table:row:deleted', {
            uuid: $(element).data('uuid'),
            action: $(element).data('action'),
            pid: $(element).data('pid')
        });

        $(element).remove();
        CheckoutDOMUtils.removeProductMiniCheckoutCart(ID);
    });

    $(document).on('click', '.js-deleteAllElements', function removeTable() {
        $('body').trigger('table:deleted', {
            type: $(this).data('type'),
            action: $(this).data('action')
        });

        $('.table-element-delete').remove();
        $('.tabs-ak__element-grid, .tabs-ak__element-list-footer').addClass('hidden');
        CheckoutDOMUtils.checkIfCartIsEmpty();
    });
});
