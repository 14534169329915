// Funciones para abrir y cerrar menús
function abrirMenu(menu) {
    menu.find(".dropdown-simple__content").css("display", "block");
}

function cerrarMenu(menu) {
    menu.find(".dropdown-simple__content").css("display", "none");
}
 
$(document).ready(function() {
    // Controladores de eventos para cada menú
    $(".dropdown-simple__option").click(function(e) {
        cerrarMenu($(".dropdown-simple__content").not($(this).parent()));
        abrirMenu($(this).parent());
        e.stopPropagation(); // Evita que el clic se propague al documento
    });

    $(".dropdown-simple__content > div").click(function() {
        var opcion = $(this).text();
        $(this).closest(".dropdown-simple").find(".dropdown-simple__option").text(opcion);
        cerrarMenu($(this).closest(".dropdown-simple"));
    });

    // Cierra todos los menús al hacer clic en cualquier parte del documento
    $(document).click(function() {
        cerrarMenu($(".dropdown-simple"));
    });
});


// dropdown v2

$(document).ready(function() {
    // Para cada contenedor de select personalizado
    $('.dropdown-v2 ').each(function() {
        var selectContainer = $(this);
        var selectStyled = selectContainer.find('.select-styled');
        var selectOptions = selectContainer.find('.select-options');
        var selectOriginal = selectContainer.find('.custom-options');

        // Crear dinámicamente las opciones
        selectOriginal.find('option').each(function() {
            var optionText = $(this).text();
            var optionDiv = $('<div>', {
                text: optionText
            });

            // Agregar la opción al contenedor
            selectOptions.append(optionDiv);
        });

        // Mostrar/ocultar la lista desplegable al hacer clic en el select personalizado
        selectStyled.click(function() {
            selectOptions.toggle();
        });

        // Manejar la selección de opciones
        selectOptions.on('click', 'div', function() {
            var selectedOption = $(this).text();
            selectStyled.text(selectedOption);
            selectOptions.hide();
            selectOriginal.val(selectedOption);
        });

        // Cerrar la lista desplegable si se hace clic fuera de ella
        $(document).on('click', function(event) {
            if (!$(event.target).closest(selectContainer).length) {
                selectOptions.hide();
            }
        });
    });
});