'use strict';

const DOMUtils = require('./DOMUtils');
const defer = $.Deferred(); // eslint-disable-line

const getAddressFieldsFromUI = (form) => {
    return {
        address1: $('input[name$=_address1]', form).val(),
        address2: $('input[name$=_address2]', form).val(),
        stateCode: $('input[name$=_stateCode]', form).val(),
        city: $('input[name$=_city]', form).val(),
        postalCode: $('input[name$=_postalCode]', form).val(),
        countryCode: $('select[name$=_country]', form).val(),
        floorNumber: $('input[name$=_floorNumber]', form).val(),
        doorNumber: $('input[name$=_doorNumber]', form).val()
    };
};

/**
 * Marks the checkbox when adding a new address.
 */
const checkNewAddressOption = () => {
    $('#address-domicilio-new').prop('checked', 'checked');
};

/**
 * Unmarks the checkbox when cancelling the addition of a new address.
 */
const uncheckNewAddressOption = () => {
    $('#address-domicilio-new').prop('checked', false);
};

/**
 * Recalculates shipping methods upon delivery address change.
 */
const updateDeliveryShippingAddress = (e) => {
    const shipmentDomicilioForm = $('div.js-shipping-method-list');
    const url = $(shipmentDomicilioForm).data('actionUrl');
    const addressUUID = $(e.target).val();

    let urlParams = getAddressFieldsFromUI(shipmentDomicilioForm);
    urlParams.addressUUID = addressUUID;
    urlParams.shipmentUUID = $('input[name="basket_shipmentUUID"]').val();

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: urlParams,
        success: (data) => {
            if (data.error) {
                window.location.href = data.redirectUrl;
            } else {
                // TODO Stop Spinner...
            }
        }
    });
};

/**
 * Recalculates shipping methods upon delivery address change.
 */
const updateShippingMethod = (e) => {
    const shippingMethodContainer = e ? `#${e.target.id.replace('-tab', '')}` : '#tabDelivery';
    const shippingMethodElement = $('ul#shippingMethodsList');
    $('input[name="shipmentMethodId"]').val($(shippingMethodContainer).data('method'));

    $.ajax({
        url: shippingMethodElement.data('actionUrl'),
        type: 'post',
        dataType: 'json',
        data: {
            shipmentUUID: shippingMethodElement.data('uuid'),
            methodID: $(shippingMethodContainer).data('method')
        },
        success: (data) => {
            if (data.error) {
                window.location.href = data.redirectUrl;
            } else {
                // TODO Stop Spinner...
                DOMUtils.updateDiscountsCart(data);
                DOMUtils.updateTotalsCart(data.order.totals);
            }
        }
    });
};

const submitShipping = (formId, tokenFormName, callback) => {
    $('#enableInputs').trigger('click');
    const shippingFormData = $('li[data-checkout="shipping"] :input').serialize();
    const contactInfoData = $('li[data-checkout="contactInfo"] :input').serialize();
    const tokenData = $('input[name="' + tokenFormName + '"]').val();

    $.ajax({
        url: $(formId).data('submitshipping-action'),
        type: 'post',
        data: `${shippingFormData}&${tokenFormName}=${tokenData}&${contactInfoData}`,
        success: (data) => {
            // TODO Handle errors if any...
            callback(data);
        },
        error: (err) => {
            if (err.responseJSON && err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }

            defer.reject(err.responseJSON);
        }
    });
};

module.exports = {
    checkNewAddressOption,
    uncheckNewAddressOption,
    updateDeliveryShippingAddress,
    updateShippingMethod,
    submitShipping,
    method: {
        DELIVERY: 'Delivery',
        CLICK_COLLECT: 'ClickAndCollect',
        IN_STORE_PICKUP: 'InStorePickUp'
    }
};
