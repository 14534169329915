'use strict';

module.exports = function (element) {
    var position = element && element.length ? element.offset().top : 0;
    $('html, body').animate({
        scrollTop: position
    }, 500);
    if (!element) {
        $('.logo-home').focus();
    }
};
