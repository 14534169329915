$(document).ready(function () {
    function closePopupContent(popup) {
        popup.find('.popup__backdrop, .popup__content').fadeOut();
    }

    $('.popup__showDiv').on('click', function openPopup(event) {
        event.preventDefault();
        const popupId = $(this).data('popup');
        const popup = $('#' + popupId);


        popup.find('.popup__textInput').val('');
        popup.find('.popup__errorMessage').empty();

        $('.popup__backdrop').fadeOut();
        popup.find('.popup__backdrop, .popup__content').fadeIn();
    });

    $('.popup__closeDiv').on('click', function closePopup() {
        closePopupContent($(this).closest('.popup__element'));
    });

    $('.popup__backdrop').on('click', function closeBackdrop(event) {
        let popup = $(event.target).closest('.popup__element');
        closePopupContent(popup);
    });

    $('.popup__element').on('click', '.popup__createCheckbox', function createCheckbox() {
        const popup = $(this).closest('.popup__element');
        let text = popup.find('.popup__textInput').val();
        popup.find('.popup__errorMessage').empty();

        if (text.trim() === '') {
            popup.find('.popup__errorMessage').text('Debes añadir un texto.');
        } else {
            let newCheckboxItem = $("<div class='popup__checkboxItem'>");
            let newCheckbox = $('<input>').attr({
                type: 'checkbox',
                class: 'popup__checkbox',
                id: 'checkbox' + (popup.find('.popup__checkbox').length + 1)
            });

            let label = $('<label>').attr('for', newCheckbox.attr('id')).text(text);

            newCheckboxItem.append(newCheckbox, label);
            popup.find('.popup__checkboxContainer').append(newCheckboxItem);
            popup.find('.popup__textInput').val('');
        }
    });
});
