window.jQuery = require('jquery');
window.$ = require('jquery');
const processInclude = require('base/util');

$(() => {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/scheduledorder'));
    processInclude(require('./components/accordion'));
    processInclude(require('./components/slick-slider-lib'));
    processInclude(require('./components/slick-slider'));
    processInclude(require('./components/dropdown'));
    processInclude(require('./components/search'));
    processInclude(require('./components/detail'));
    processInclude(require('./components/newmodal'));
    processInclude(require('./components/table-delete'));
    processInclude(require('./components/popup'));
    processInclude(require('./login/login'));
    processInclude(require('./components/chart-lib.js'));
    processInclude(require('./components/chart.js'));
    processInclude(require('./components/timeslot-listeners'));
    processInclude(require('./components/timeslot'));
});

require('./thirdParty/bootstrap');
require('./thirdParty/owlcarousel');
require('base/components/spinner');
