const cart = require('../cart/cart');

let updateMiniCart = true;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    const status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);

    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    const $targetElement = $(`a[data-pid="${data.pid}"]`).closest('.product-info').find('.remove-product');
    const itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid')
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(() => {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function f(e) {
        e.preventDefault();
        const url = $(this).attr('href');
        const pid = $(this).data('pid');
        let optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        let optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: {
                pid,
                optionId,
                optionVal
            },
            success(data) {
                displayMessageAndRemoveFromCart(data);
            },
            error(err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
}

module.exports = () => {
    cart();
    moveToWishlist();
    $('.minicart').on('count:update', (event, data) => {
        let grandTotal;
        if (data && (data.cart || data.totals || data.basket)) {
            /* eslint-disable no-nested-ternary */
            grandTotal = data.cart && data.cart.totals && data.cart.totals.grandTotal
                ? data.cart.totals.grandTotal : data.totals && data.totals.grandTotal
                    ? data.totals.grandTotal : data.basket && data.basket.totals && data.basket.totals.grandTotal
                        ? data.basket.totals.grandTotal : null;
            $('.minicart-total span').text(grandTotal);
            $('.minicart-total span').data('subtotal', data.quantityTotal || data.numItems);
            $('.minicart .minicart-link').attr({
                'aria-label': data.minicartCountOfItems,
                title: data.minicartCountOfItems
            });
            let itemsCart = null;

            if (data && data.cart && data.cart.items) {
                itemsCart = data.cart;
            } else if (data && data.items) {
                itemsCart = data;
            }
            // only needed when new products are added, on plp qty increase this isn't needed
            if (itemsCart) {
                const itemsquantities = [];
                const { items } = itemsCart;

                items.forEach((item) => {
                    itemsquantities.push({ id: item.id, qty: item.quantity });
                });
                $('.cart-json').data('cart', { itemsquantities });
            }
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', () => {
        let subTotal = $('.minicart-total span').data('subtotal');
        let blockMiniCart = typeof subTotal === 'undefined' || subTotal <= 0;
        if ($('.search:visible').length === 0 || blockMiniCart) {
            return;
        }
        const url = $('.minicart').data('action-url');
        const count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }

            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, (data) => {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    $('body').on('touchstart click', (e) => {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', (event) => {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function f() {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', () => {
        updateMiniCart = true;
    });
    $('body').on('cart:update', () => {
        updateMiniCart = true;
    });

    $('body').on('click', '.cancel-all-products', function cancel(e) {
        e.preventDefault();
        let url = $(this).data('action');
        $('body > .modal-backdrop').remove();
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            success: (data) => {
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                } else {
                    location.reload();
                }
            },
            error: () => {
                $.spinner().stop();
                location.reload();
            },
            complete: () => {
                $.spinner().stop();
            }
        });
        return false;
    });
};
