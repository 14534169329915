'use strict';

module.exports = {
    getCookieById: (cookieName) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1);
            }
        }
        return null;
    },

    setCookie: (id, content, days) => {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = id + '=' + (content || '') + expires + '; path=/';
    }
};
