'use strict';

/**
 * Adds products to cart by appending data to the element list.
 * @param {string} data - The product data to be appended to the checkout cart.
 */
const addProductsMinicaCheckoutCart = (data) => {
    $('.tabs-ak__element-list-elements').append(data);
};

const removeProductMiniCheckoutCart = (pid) => {
    const elementToRemove = $(`div[data-minicheckout-product='${pid}']`);
    if ($(elementToRemove).length) {
        $(elementToRemove).remove();
    }
};

const updateQuantityMiniCheckoutCart = (data) => {
    if (data?.items?.length > 0) {
        data.items.forEach((item) => {
            const quantityValue = item.quantity * item.unitData.value;
            const $input = $(`div[data-minicheckout-product="${item.id}"] input[name="itemquantity"]`);
            $input.val(quantityValue);
            $input.attr('data-quantity', item.quantity);
        });
    }
};

const updatePriceMiniCheckoutCart = (data) => {
    if (data?.items?.length > 0) {
        data.items.forEach((item) => {
            $(`div[data-minicheckout-product="${item.id}"] span[data-price]`).text(item.priceTotal.price);
        });
    }
};

const removeProductCart = (pid) => {
    const elementToRemove = $(`div.table-element-delete[data-pid='${pid}']`);
    if ($(elementToRemove).length) {
        $(elementToRemove).remove();
    }
};

/**
 * Updates the cart totals with the latest basket data
 * @param {Object} totals - totals data
 */
const updateTotalsCart = (totals) => {
    $('.js-checkout-subtotal').text(totals.subTotal);
    $('.js-checkout-envio').text(totals.totalShippingCost);
    $('.js-checkout-total').text(totals.grandTotal);

    const descuentosLabel = $('.js-checkout-descuentos-label');
    const descuentosContainer = $('.js-checkout-descuentos-cnt').empty();
    const discountInfoContainer = $('<div class="cart-discounts pt-2"></div>');
    if (totals.discounts.length > 0) {
        descuentosLabel.removeClass('u-hidden');
        $(descuentosContainer).removeClass('u-hidden');
        totals.discounts.forEach((discount) => {
            const discountInfo = $(`
                <div class="promotion-information">
                    <div class="promotion-name">${discount.lineItemText}</div>
                    <div class="applied-promotion-discount">${discount.price}</div>
                </div>
            `);
            $(discountInfoContainer).append(discountInfo);
        });
        $(descuentosContainer).append(discountInfoContainer);
    } else {
        descuentosLabel.addClass('u-hidden');
        $(descuentosContainer).addClass('u-hidden');
    }
};

/**
 * Update discount to applied applied
 * @param {string} couponItemsRendered discounts to render
 */
const updateCuponsApplied = (couponItemsRendered) => {
    const cuponsAppliedContainer = $('.js-checkout-cupons-applied').empty();
    cuponsAppliedContainer.append(couponItemsRendered);
};

const checkIfCartIsEmpty = () => {
    if ($('.table-element-delete').length === 0) {
        $('.js-deleteAllElements').hide();
        $('.tabs-ak__element-grid, .tabs-ak__element-list-footer').addClass('hidden');
        $('.table-delete__message ').show();
    }
};

/**
 * Updates the header with the subtotal amount
 * @param {Object} totals - totals data
 */
const updateHeaderTotal = (totals) => {
    $('.minicart-total span[data-subtotal]').text(totals.subTotal);
};

/**
 * Updates the price for each product Line item
 * @param {List} items - Cart Items
 */
const updateItemsCart = (items) => {
    items.forEach((item) => {
        if (item.renderedPromotions) {
            $(`.item-${item.UUID}`).empty().append(item.renderedPromotions);
        }
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $(`.item-total-${item.UUID}`).empty().append(item.priceTotal.renderedPrice);
        }
    });
};

/**
 * Updates the discounts section with the latest basket data
 * @param {Object} basket - Basket data
 */
const updateDiscountsCart = (basket) => {
    $('div.js-shipment-container').html(basket.freeShippingHTML);
};

module.exports = {
    removeProductMiniCheckoutCart,
    updateQuantityMiniCheckoutCart,
    updatePriceMiniCheckoutCart,
    checkIfCartIsEmpty,
    removeProductCart,
    updateItemsCart,
    updateDiscountsCart,
    updateTotalsCart,
    updateCuponsApplied,
    updateHeaderTotal,
    addProductsMinicaCheckoutCart
};
