'use strict';

var formValidation = require('../components/formValidation');
const cookiesUtil = require('../cookiesUtil');

module.exports = {
    login: () => {
        $('form.login').submit(function login(e) {
            var form = $(this);
            var url = form.attr('action');
            e.preventDefault();
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: (data) => {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data, true);
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        cookiesUtil.setCookie('logged', true);
                        location.href = data.redirectUrl;
                    }
                },
                error: (data) => {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        formValidation(form, data.responseJSON, true);
                        $('form.login').trigger('login:error', data.responseJSON);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });

        $('.js-show-pass').each(function () {
            const $group = $(this);

            const $passwordInput = $group.find('input[type="password"]');
            const $showPassIcon = $group.find('.icon-close-eye');
            const $hiddenPassIcon = $group.find('.icon-open-eye');

            $showPassIcon.click(() => {
                $passwordInput.attr('type', 'text');
                $showPassIcon.hide();
                $hiddenPassIcon.show();
            });

            $hiddenPassIcon.click(() => {
                $passwordInput.attr('type', 'password');
                $hiddenPassIcon.hide();
                $showPassIcon.show();
            });
        });
    },
    resetPassword: () => {
        $('.reset-password-form').submit(function reset(e) {
            var form = $('form[name="email-form"]');
            var url = form.attr('action');
            e.preventDefault();
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: (data) => {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $(form).find('.form-control.is-invalid').removeClass('is-invalid');
                        $('.alert', form).remove();
                        $('#stepReset').hide();
                        $('#stepSent').removeClass('d-none').show();
                    }
                },
                error: () => {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: () => {
        $('#login .modal').on('hidden.bs.modal', function clear() {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
            $('#stepSent').hide();
            $('#stepReset').show();
        });
    },

    openGuestInput: () => {
        $(document).on('click', '.js-open-guest', () => {
            $('.form-dropdown__content').addClass('show');
        });
    }
};
